$fontFamily: "neue-haas-grotesk-display";

$themeColor: #FFC10D;
$dark: #1E2131;
$light: #D1D1D1;
$lighter: #E5E5E5;

//media queries
$tabletMax: 767px;
$tabletMin: 768px;
$laptopMax: 991px;
$laptopMin: 992px;
$desktopMax: 1199px;
$desktopMin: 1200px;