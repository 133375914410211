html {
    scroll-behavior: smooth;
}
 
body{
    font-family: $fontFamily;
    letter-spacing: 1px;
}

h1{
    font-weight: 200;
    font-size: 56px;
    color: $themeColor;
    letter-spacing: 2px;
    @media (min-width: $tabletMin){
        font-size: 78px;
    }
    @media (min-width: $laptopMin){
        font-size: 90px;
    }
}

h2{
    font-weight: 400;
    font-size: 30px;
    color: $dark;
    @media (min-width: $tabletMin){
        font-size: 35px;
    }
    @media (min-width: $laptopMin){
        font-size: 40px;
    }
}

h3{
    font-weight: 200;
    font-size: 26px;
    color: $themeColor;
    @media(min-width: $laptopMin){
        font-size: 33px;
    }
}

h4{
    font-weight: 400;
    font-size: 30px;
    color: $dark;
}

h5{
    font-size: 16px;
    font-weight: 500;
    color:$dark;
}

p{
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    color:$dark;
}
a{
    font-size: inherit;
    font-weight: 400;
    letter-spacing: 1px;
    color: $dark;
    @include transition(0.15s);  
    &:hover{
        text-decoration: none;
        color: $dark;
    }
}
li{
    list-style: none; 
}
input{
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    color:$dark; 
}
small{
    font-size: 12px;
    font-weight: 400;
    color:$dark;
    letter-spacing: 0.7px;
}

img{
    max-width: 100%;
}