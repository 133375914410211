.ldb_logo {
    width: 100px; /* Adjust as needed */
}

._wrapper{
    @include transition(1s);
    visibility: visible;
    opacity: 1;
    &._hide{
        visibility: hidden;
        opacity: 0;
    }
}
._landing-section {
    position: relative;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
}



header{
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    background-color: #fff;
    height: 100px;
    .ldb_left-panel{
        padding: 20px 0;
    }
    .ldb_right-panel{
        padding: 30px 0;
        display: flex;
        justify-content: flex-end;
    }
}

.ldb_mobile-menu{
    position: fixed;
    top: 100px;
    left: 100%;
    width: 100%;
    height: calc(100% - 100px);
    background-color: #fff;
    text-align: center;
    @include transition(0.25s);
    @include delay(0.25s);
    padding: 0 15px 20px 15px;
    z-index: 3;
    overflow: auto;
    .ldb_list-menu{
        padding: 0 0 40px 0;
        li{
            padding: 12px 0;            
            a{
                padding: 12px 0;
                font-size: 30px;
                font-weight: 400;
                text-transform: lowercase;
                &.active{
                    border-bottom: 3px solid $themeColor;
                }
            }
        }
    }
    ul{
        padding-left: 0;
    }
    &.open{
        left: 0;
    }
}

.ldb_list-widescreen-menu{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    height: 100%;
    li{
        padding: 20px 12px;
        a{
            padding: 20px 0;
            @include transition(0.25s);
            border-bottom: 3px solid transparent;
            text-transform: lowercase;
            &.active{
                border-bottom: 3px solid $themeColor;
            }
        }
        &:hover{
            a{
                border-bottom: 3px solid $themeColor;
            }
        }
        &:active{
            a{
                border-bottom: 3px solid $themeColor;
            }
        }
        &:focus{
            a{
                border-bottom: 3px solid $themeColor;
            }
        }
    }
}

.ldb_list-social{
    display: flex;
    align-items: center;
    padding-left: 0;
    li{
        padding: 0 8px;
    }
}

.ldb_mobile-col{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (min-width:$laptopMin){
        display: none;
    }
    .ldb_list-social{
        justify-content: center;
    }
}

.ldb_widescreen-col{
    display: none;
    @media (min-width:$laptopMin){
        display: block;
    }
}

.ldb_social-col{
    display: none;
    .ldb_list-social{
        height: 100%;
        justify-content: flex-end;
    }
    @media (min-width:$laptopMin){
        display: block;
    }
}

main{
    margin-top: 100px;
    min-height: 600px;
    @media(min-width: $desktopMin){
        min-height: calc(100vh - 169px);
    }
}

.ldb_top-section{
    padding: 20px 0 40px 0;
    
    @media(min-width: $laptopMin){
        padding: 110px 0 100px 0;
    }
}

.ldb_top-section-heading {
    padding: 20px 0 40px 0;

    @media(min-width: $laptopMin){
        padding: 110px 0 100px 0;
    }
}

.ldb_contact_top_section {
    display: flex;
    align-items: center; /* Vertically align content */

    padding: 10px 0 20px 0;
    
    @media(min-width: 1200px){
        padding: 50px 0 50px 0;
    }
}


.ldb_sustainability-top-section{
    padding: 20px 0 40px 0;
    
    @media(min-width: $laptopMin){
        padding: 110px 0 100px 0;
    }
}

footer{
    padding: 10px 0;
    border-top: 1px solid $lighter;
}

.ldb_designer-layout-section{
    figure{
        margin-bottom: 0;
    }    
    .ldb_column{
        margin-bottom: 60px;
        .ldb_page-heading{
            @media(max-width: $laptopMax){
                margin-bottom: 60px;
            }            
        }
    }    
    .ldb_column-4{
        margin-bottom: 10px;
    }
    .ldb_column-6{
        margin-bottom: 0;
    }
    @media(min-width: $laptopMin){
        .ldb_column{
            margin-bottom: 100px;
        }
        .ldb_column-2{
            width: 55%;
            float: left;
        }
        .ldb_column-3{
            width: 35%;
            float: right;
        }
        .ldb-designer-container{
            position: relative;
            height: 0;
            margin-bottom: 550px;
        }
        .ldb_column-4{
            width: 50%;
            position: absolute;
            left: 29%;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            z-index: 1;
        }
        .ldb_column-5{
            width: 40%;
            position: absolute;
            right: 0;
            top: calc(100% + 190px);
        }
        .ldb_column-6{
            width: 35%;
            position: absolute;
            left: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
    @media(min-width:$desktopMin){
        .ldb_column-2{
            width: 40%;
        }
        .ldb_column-4{
            width: 60%;
        }
        .ldb_column-5{
            top: calc(100% + 180px);
        }
    }
}

.ldb_products-item{
    position: relative;
    padding-bottom: 87.5%;
    background-position: top;
    background-size: 106%;
    background-repeat: no-repeat;
    @include transition(0.25s);
    @include delay(0.25s);
    cursor: pointer;
    margin-bottom: 30px;
    
    .ldb_title{
        position: absolute;
        bottom: 0;
        left: 0;
        color: black;
        font-size: 16px;
        margin-bottom: 15px;
    }
    a{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
    }
    .ldb_arrow{
        margin-left: 10px;
    }
    
    @media (min-width: 439px){
        padding-bottom: 84.5%;
    }
    
    @media (min-width: $tabletMin){
        padding-bottom: 89%;
    }
    @media (min-width: $laptopMin){
        padding-bottom: 85%;
        
        .ldb_arrow{
            display: none;
        }
    }
}