.ldb_hamburger-menu-icon{
    width: 40px;
    height: 40px;
    position: relative;
    cursor: pointer;
    span{
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 5px;
        background-color: $dark;
        position:absolute;        
        transform: translateY(-50%);
        @include transition(0.25s);
        &:nth-of-type(1){
            top: 40%;
        }
        &:nth-of-type(2){
            top: 60%;
        }
    }
    &.open{
        span{
            &:nth-of-type(1){
                top: 50%;
                transform: rotate(45deg)
            }
            &:nth-of-type(2){
                top: 50%;
                transform: rotate(-45deg)
            }
        }        
    }
}

.ldb_horline{
    width: 120px;
    height: 3px;
    background-color: $themeColor;
    @media(min-width: $tabletMin){
        width: 220px;
    }
}

.ldb_page-heading{
    line-height: 3.6rem;
    @media(min-width: $tabletMin){
        line-height: 5rem;
    }
    @media(min-width: $laptopMin){
        line-height: 6rem;
    }
    @media(min-width: $desktopMin){
        margin-top: -15px;
    }    
}

.ldb_section-heading{
    margin-bottom: 60px;
}

.ldb_copywright-text{
    display: block;
    text-align: center;
    margin: 15px 0;
}

.ldb_carousel-section{
    overflow: hidden;
    @media(min-width:$desktopMin){
        padding: 100px 0 0px 0;
    }
    .ldb_carousel-right{
        @media(min-width: $desktopMin){
            width: 50vw;
            .slick-track{
                margin-left: -60px;
            }
        }        
    }
    .ldb_copywright-text{
        text-align: left;
    }
}

.ldb_carousel-item-left{
    height: 175px;
    @media(min-width: $tabletMin){
        height: 200px;
    }
    @media(min-width: $desktopMin){
        height: 400px;
    }
}

.ldb_caltoaction-button{
    z-index: 1;
    position: relative; 
    padding: 20px 0;
    display: inline;
    a{
        padding: 20px 0;
    }
    .ldb_arrow{
        margin-left:10px;
    }
    &:hover{
        .ldb_arrow{
            fill: $themeColor;
        }
    }
    @media (min-width:$laptopMin) {
        &:hover{
            .ldb_arrow{
                margin-left:20px;
            }
        }
    }
}

.ldb_backonelevel-button{
    margin-bottom: 50px;
    display: inline-block;
    a{
        font-size: 12px;
        @include transition(0.15s);
        @include delay(0.15s);
    }
    .ldb_arrow{
        margin-right:10px;
        position: relative;
        left: 0;
        fill: #D1D1D1;
    }

    &:hover{
        .ldb_arrow{
            fill: $themeColor;
        }
    }
    
    @media(min-width:$laptopMin){
        &:hover{
            .ldb_arrow{
                left: -20px;
            }
        }
    }
}

.ldb_arrow {
    @include transition(0.15s);
    @include delay(0.15s);
    &:hover{
        fill: $themeColor;
    }
}

.ldb_carousel-item-right{
    @media(min-width:$desktopMin){
        padding-right: 20px;
    }    
}

.ldb_carousel-progress-bar-div{
    display: flex;
    margin: 20px 0 20px 0;
    @media(min-width: $desktopMin){
        margin: 50px 0 30px 0;
    }
    .ldb_carousel-progress-bar{
        width: 100%;
        height: 3px;
        margin: 13px 0;
        .progress-bar{
            background-color: $themeColor;
        }
    }
    .ldb_numbers{
        width: 100px;
        display: flex;
        justify-content: flex-end;
        p{
            margin: 0 10px;
        }
    }
}

.ldb_video{
    width: 100%;
    position: relative;
    padding-bottom: 56%;
    iframe, video{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}

.ldb_next-prev-nav-team{
    display: flex;
    justify-content: center;
    .ldb_inner{
        padding: 30px 20px 10px 20px;
        position:relative;
        display: flex;
        align-items: center;
        svg{
            @include transition(0.15s);
            @include delay(0.15s);
        }
        &.ldb_prev{
            svg{
                position:absolute;
                right: 80%;
                fill: #D1D1D1;
            }
            &:hover{
                svg{
                    right: 90%;
                    fill: $themeColor;
                }
            }
        }
        &.ldb_next{
            svg{
                position:absolute;
                left: 80%;
                fill: #D1D1D1;
            }
            &:hover{
                svg{
                    left: 90%;
                    fill: $themeColor;
                }
            }
        }
    }
}

.ldb_next-prev-nav-projects{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 60px 0;

    .ldb_page-heading{
        font-weight: 200;
        font-size: 30px;
        line-height: 30px;
        color: #FFC10D;
        letter-spacing: 2px;
        margin-bottom: 0;
        @include transition(0.15s);
        @include delay(0.15s);
        text-align: center;
        &:hover{
            transform: scale(1.1);
        }
        @media (min-width: $tabletMin){
            font-size: 40px;
        }
    }
    .ldb_inner{
        
        position: relative;
        display: flex;
        align-items: center;
        font-size: 12px;
        svg{
            @include transition(0.15s);
            @include delay(0.15s);
        }
        &.ldb_prev{
            padding: 30px 0 30px 30px;
            svg{
                position:absolute;
                left:0;
                fill: #D1D1D1;
            }
            &:hover{
                svg{
                    fill: $themeColor;
                }
            }
            @media (min-width:$laptopMin){
                &:hover{
                    svg{
                        left: -10px;
                    }
                }
            }
        }
        &.ldb_next{
            padding: 30px 30px 30px 0;
            svg{
                position:absolute;
                right: 0;
                fill: #D1D1D1;
            }
            &:hover{
                svg{
                    fill: $themeColor;
                }
            }
            @media (min-width:$laptopMin){
                &:hover{
                    svg{
                        right: -10px;
                    }
                }
            }
        }
    }
}

.ldb_popup-wrap{
    position:fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1000;
    .ldb_overlay{
        position: absolute;
        background-color: #fff;
        left: 0;
        top:0;
        width: 100%;
        height: 100%;
        z-index: 0;
        cursor: pointer;
    }
    .ldb_popup-inner{
        width: calc(100% - 50px);
        max-width: 960px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .ldb_close-popup{
        background-color: transparent;
        border: none;
        position: absolute;
        top: 0;
        right: 0;
        padding: 25px;
    }
}

.ldb_button{
    border: 1px solid $themeColor;
    background: transparent;
    padding: 10px 20px;
    color: $dark;
}

.error {
    color: red;
}