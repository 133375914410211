// .ldb_products-section{
//     padding-top: 80px;
// }

.ldb_associated-products-section{
    padding-top: 140px;
}

.ldb_product-section{
    padding-top: 60px;
    figure{
        margin-bottom: 30px;
        width: 100%;
        @media(min-width: $laptopMin){
            margin-bottom: 70px;
        }
        img{
            width: 100%;
        }
    }
}

.ldb_team-section {
    .custom-col {
        width: 20%; // 100% / 5 = 20%
        float: left;
        padding: 0 15px; // Optional: Adjust spacing between columns
        box-sizing: border-box;
        margin-bottom: 30px;

        @media(min-width: $laptopMin) {
            margin-bottom: 60px;
        }

        @media (max-width: 1200px) { // Adjust for smaller screens
            width: 25%; // 4 columns for tablets
        }

        @media (max-width: 768px) {
            width: 50%; // 2 columns for small devices
        }

        @media (max-width: 576px) {
            width: 100%; // 1 column for extra small devices
        }
    }

    .ldb_item {
        position: relative;
        z-index: 1;
        cursor: pointer;
        background-color: #F7F7F7;
        overflow: hidden; // Ensures no extra space around the image.
        display: flex; // Aligns children properly.
        flex-direction: column; // Ensures vertical stacking of elements.
        justify-content: flex-start; // Aligns children to the top.
        height: 100%; // Matches the figure's height.

        &:hover {
            figcaption {
                opacity: 1;
            }
        }

        a {
            @include innerLinkBlock;
        }
    }

    figure {
        margin: 0; // Remove extra margins.
        padding: 0; // Remove padding.
        height: 100%; // Matches parent height.
        width: 100%; // Matches parent width.
        display: flex;
        align-items: center; // Center the image inside the figure.
        justify-content: center;
    }

    img {
        width: 100%; // Ensures it fills the figure width.
        height: 100%; // Ensures it fills the figure height.
        object-fit: cover; // Prevents distortion.
    }

    figcaption {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;

        text-align: center;
        opacity: 1;
        @include transition(0.25s);
        @include linierGradient;

        @media (min-width: $laptopMin) {
            opacity: 0;
        }
    }

    .ldb_title {
        color: white;
        margin-bottom: 5px;
        font-size: 20px;
        // font-family: Helvetica Neue,;
        padding-top: 0;
        // font-weight: lighter;
    }

    .ldb_description {
        color: #fff;
        display: block;
        padding-bottom: 15px;
        font-size: 12px;
    }

    @media (min-width: $laptopMin) {
        .ldb_item {
            margin-bottom: 60px;
        }
    }
}



.ldb_aftercare-section{
    @media(min-width:$laptopMin){
        .ldb_column-4{
            width: 90%;
            left: 10%;
        }
    }
    @media(min-width:$desktopMin){
        .ldb_column-4{
            width: 80%;
            left: 24%;
        }
    }
}

.ldb_contact-section{
    padding: 30px 0 30px 0;
    .ldb_page-heading{
        margin: 0px 0 30px 0;
        @media(min-width:$laptopMin){
            margin: 60px 0 30px 0;
        }
    }
    .ldb_contact-figure{
        @media(min-width:$laptopMin){
            margin-top: 30px;
        }
    }
    P{
        margin-bottom: 40px;
    }
    @media(min-width:$laptopMin){
        padding-top: 40px;
    }
    .ldb_google-map {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 70%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
        /* Then style the iframe to fit in the container div with full height and width */
        .ldb_google-map-iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;            
        }
        @media (min-width: $laptopMin){
            padding-top: 110%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
        }
        @media (min-width: $desktopMin){
            padding-top: 80%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
        }
    }    
}

.ldb_contact-image{
    margin-bottom: 30px;

    @media(min-width: $laptopMin) {
        margin-bottom: 60px;
    }
}

.ldb_contact-text {
    margin-bottom: 30px;
    padding-top: 30px;

    @media(min-width: $laptopMin) {
        margin-bottom: 60px;
        padding-top: 60px;
    }
}

.ldb_member-section{
    padding-top: 30px;   
    .ldb_page-subheading{
        margin-bottom: 30px;
    }
    .ldb_page-heading{
        margin-top: 10px;
    }
    figure{
        width: 100%;
        max-width: 350px;
        margin: 0 auto;        
        img{
            min-height:250px;
            background-color: $lighter;
        }
    }
    @media(min-width:$laptopMin){
        padding-top: 60px;
        .ldb_page-subheading{
            margin-bottom: 60px;
        }
        figure{
            margin: 0;
        }
    }
}

.ldb_legal-section{
    .ldb_page-heading{
        margin: 50px 0 30px 0;
    }
    h2{
        color: $themeColor;
    }
    li{
        list-style: initial;
    }
}

.ldb_accreditation-section{
    .ldb_page-heading{
        margin: 50px 0 30px 0;
    }
    h2{
        color: $themeColor;
    }
    li{
        list-style: initial;
    }
}

.full-width-container {
    width: 100% !important; /* Ensures it spans full width */
    max-width: 100% !important; /* Removes Bootstrap's max-width constraint */
    padding: 30px; /* Optional: Removes default padding */
}

.ldb_about-section{
    width: 100%;
    .ldb_video{
        margin-bottom: 30px;
        @media(min-width: $laptopMin){
            margin-bottom: 60px;
        }
    }
    .ldb_section-heading{
        color: $themeColor;
        font-weight: 300;
        margin-bottom: 30px;
    }
    .ldb_about-bodycopy{
        margin-bottom: 30px;
        padding-top: 30px;
        @media(min-width: $laptopMin){
            margin-bottom: 60px;
            padding-top: 60px;
        }
    }
    .ldb_about-images{
        margin-bottom: 30px;

        @media(min-width: $laptopMin) {
            margin-bottom: 60px;
        }
    }
}

.ldb_aftercare-section{
    .ldb_aftercare-figure{
        margin-bottom: 30px;
        @media(min-width: $laptopMin){
            margin-bottom: 60px;
        }
    }
    .ldb_section-heading{
        color: $themeColor;
        font-weight: 300;
        margin-bottom: 30px;
    }
    .ldb_aftercare-bodycopy{
        padding-top: 30px;
        margin-bottom: 30px;
        @media(min-width: $laptopMin){
            margin-bottom: 60px;
            margin-top: 60px;
        }
    }
    .ldb_aftercare-images{
        margin-bottom: 30px;
        @media(min-width: $laptopMin) {
            margin-bottom: 60px;
        }
    
    }
}

.ldb_form-rsvp{
    margin: 50px 0 30px 0;
    .ldb_button{
        min-width: 140px;
    }
    @media(min-width: $tabletMin){
        margin-top: 70px;
    }
}


._landing-section{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-position: center;
    background-size: cover;
    @include transition(1s);
    visibility: visible;
    opacity: 1;
    cursor: pointer;
    ._heading{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 25px;
        text-transform: uppercase;
        letter-spacing: 6px;
        line-height: 50px;
        color: #fff;
        font-weight: 400;
        text-align: center;
        width: 100%;
        span._dot{
            font-size: 35px;
            font-weight: 300;
        }
        span{
            display: block;
        }
        @media(min-width: $tabletMin){
            font-size: 35px;
        }

        @media(min-width: 1060px){
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            
            span{
                display: inline;
            }
        }
    }
    .ldb_logo{
        position: absolute;
        top: 100px;
        left: 100px;
        max-height: 20000px;

    }
    &._hide{
        visibility: hidden;
        opacity: 0;
    }
}


.ldb_sustainability-section{
    .ldb_section-heading{
        font-weight: 300;
        margin-bottom: 30px;
    }
    .ldb_horline{
        width: 120px;
        height: 3px;
        @media(min-width: $tabletMin){
            width: 220px;
        }
    }
    .ldb_sustainability-bodycopy{
        margin-bottom: 30px;
        padding-top: 30px;
        ul{
            li{
                list-style: disc;
                padding-left: 30px;
            }
        }
        @media(min-width: $laptopMin){
            margin-bottom: 60px;
        }
    }
    .ldb_sustainability-images{
        margin-bottom: 30px;

        @media(min-width: $laptopMin){
            margin-bottom: 60px;
        }
    }
}

