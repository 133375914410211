@mixin transition($speed){
    -webkit-transition: ease-in-out $speed;
    -moz-transition: ease-in-out $speed;
    -ms-transition: ease-in-out $speed;
    transition: ease-in-out $speed;
}

@mixin delay($duration){
    -webkit-transition-delay: $duration;
    -moz-transition-delay: $duration;
    -ms-transition-delay: $duration;
    transition-delay: $duration;
}

@mixin center {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@mixin leftCenter {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin innerLinkBlock{
    position: absolute;
    left: 0;
    right: 0;;
    top: 0;
    bottom: 0;
}

@mixin linierGradient(){
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.8576564414828431) 0%, rgba(0,0,0,0.4711018196341037) 50%, rgba(0,0,0,0) 100%);
}